import { postMessageToApp } from '../src/utils/postMessageToApp';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('message', (event) => {
    console.debug('[app] page received message from serviceworker', event);
    postMessageToApp(event.data);
  });

  window.addEventListener('message', (event) => {
    if (
      typeof event.data !== 'string' ||
      !event.data ||
      event.data[0] !== '{'
    ) {
      return;
    }

    const data = JSON.parse(event.data);
    console.debug('[app] page received message from app', event);

    switch (data.type ?? data.action) {
      case 'authorization': {
        navigator.serviceWorker.controller.postMessage(event.data);
        location.reload();
        break;
      }

      case 'insets': {
        navigator.serviceWorker.controller.postMessage(event.data);

        const style = document.querySelector<HTMLStyleElement>(
          'style[data-app="insets"]'
        );

        if (style) {
          const insets = (data.value as string)
            .split(';')
            .reduce<Record<string, number>>((insets, inset) => {
              const [key, value] = inset.trim().split('=');
              insets[key] = Number(value);
              return insets;
            }, {});

          style.innerHTML = `
            :root {
              --reachora-insets-top: ${insets.top ?? 0}px;
              --reachora-insets-right: ${insets.right ?? 0}px;
              --reachora-insets-bottom: ${insets.bottom ?? 0}px;
              --reachora-insets-left: ${insets.left ?? 0}px;
            }
          `;
        }
        break;
      }

      case 'back': {
        window.history.back();
        break;
      }

      case 'navigate': {
        location.href =
          typeof data.value === 'string' ? data.value : data.value.redirect_to;
        break;
      }

      case 'reload': {
        location.reload();
        break;
      }
    }

    postMessageToApp({ type: 'debug', value: event.data });
  });
}

// When a page loads, report its title. Don't care about turbolinks because that
// should be disabled all together.
document.addEventListener('DOMContentLoaded', () => {
  const settings = document.querySelector<HTMLMetaElement>(
    'meta[name="header-link-settings"]'
  );

  postMessageToApp({ type: 'debug', value: 'DOMContentLoaded' });

  if (document.title) {
    postMessageToApp({ type: 'title', value: document.title });
    postMessageToApp({
      type: 'title.settings',
      value: settings?.content || null,
    });
  }
});
