// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function postMessageToApp(data: any): void {
  if ('ReactNativeWebView' in window) {
    const nativeWindow = window as Window &
      typeof globalThis & {
        ReactNativeWebView: { postMessage(message: string): string }
      }
    nativeWindow.ReactNativeWebView.postMessage(JSON.stringify(data))
  } else {
    try {
      if (window.self === window.top) {
        console.debug('[app] no native webview or web iframe detected')
        return
      }
    } catch {
      // no-op
    }

    console.debug('[app] found web iframe, posting to parent')
    window.parent.postMessage(JSON.stringify(data), '*')
  }
}

export function canPostMessageToApp(): boolean {
  return ('ReactNativeWebView' in window)
}